import { NextLinkComposed } from '@livekatsomo/web/ui-components/link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

export interface EmailVerifiedProps {
  continueUrl: string;
}

/**
 * Renders a component to display a message indicating that the email has been verified.
 * @param props - The component props.
 * @param props.continueUrl - The URL to redirect the user to after verification.
 * @returns The rendered component.
 */
export function EmailVerified({ continueUrl }: EmailVerifiedProps) {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h3">{t('Email verified!')}</Typography>
      {continueUrl ? (
        <Button variant="text" to={continueUrl} component={NextLinkComposed}>
          {t('Continue to where you left of')}
        </Button>
      ) : null}
    </>
  );
}
