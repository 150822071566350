import { SigninProcessStatus } from '@livekatsomo/types';
import Facebook from '@mui/icons-material/Facebook';
import Google from '@mui/icons-material/Google';
// import Twitter from '@mui/icons-material/Twitter';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

/**
 * Props for the SignInWithProviders component.
 */
export interface SignInWithProvidersProps {
  /**
   * Callback function for Google sign-in.
   */
  onGoogleSignIn: () => Promise<void>;
  /**
   * Callback function for Facebook sign-in.
   */
  onFacebookSignIn: () => Promise<void>;
  /**
   * Callback function for Twitter sign-in.
   */
  onTwitterSignIn: () => Promise<void>;
  /**
   * Optional status of the sign-in process.
   */
  processing?: SigninProcessStatus;
}

/**
 * Renders a component with buttons to sign in with Google, Facebook, and Twitter.
 * @param props - The component props.
 * @param props.onGoogleSignIn - The function to call when the user clicks the "Sign in with Google" button.
 * @param props.onFacebookSignIn - The function to call when the user clicks the "Sign in with Facebook" button.
 * @param props.onTwitterSignIn - The function to call when the user clicks the "Sign in with Twitter" button.
 * @param props.processing - The name of the provider that is currently being processed, or an empty string if no provider is being processed.
 * @returns The rendered component.
 */
export function SignInWithProviders({
  onGoogleSignIn,
  onFacebookSignIn,
  onTwitterSignIn,
  processing,
}: SignInWithProvidersProps) {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6" component="h2" align="center">
        {t('Sign in using sign in providers')}
      </Typography>
      <LoadingButton
        fullWidth
        variant="outlined"
        loading={processing === 'google.com'}
        loadingPosition="start"
        startIcon={<Google />}
        disabled={!!processing}
        onClick={onGoogleSignIn}
      >
        {t('Sign in with Google')}
      </LoadingButton>
      <LoadingButton
        fullWidth
        variant="outlined"
        loading={processing === 'facebook.com'}
        loadingPosition="start"
        startIcon={<Facebook />}
        disabled={!!processing}
        onClick={onFacebookSignIn}
      >
        {t('Sign in with Facebook')}
      </LoadingButton>
      {/* <LoadingButton
        fullWidth
        variant="outlined"
        loading={processing === 'twitter.com'}
        loadingPosition="start"
        startIcon={<Twitter />}
        disabled={!!processing}
        onClick={onTwitterSignIn}
      >
        {t('Sign in with Twitter')}
      </LoadingButton> */}
    </>
  );
}
