import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { useTranslation } from 'next-i18next';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

/**
 * Props for the SelectField component.
 * @typeParam Option The type of the options in the select field.
 * @typeParam Value The type of the selected value(s) in the select field.
 * @typeParam TFormValues The type of the form values object.
 */
export interface SelectFieldProps<
  Option,
  Value extends string | number | readonly string[] | undefined,
  TFormValues extends FieldValues,
> extends Omit<FormControlProps, 'onChange'> {
  /**
   * The options to render in the select input.
   */
  options: Option[];
  /**
   * The name of the field in the form values object.
   */
  name: Path<TFormValues>;
  /**
   * The label for the select input.
   */
  label: string;
  /**
   * Include none as an option.
   * @default false
   */
  includeNone?: boolean;
  /**
   * The `react-hook-form` control object.
   */
  control?: Control<TFormValues>;
  /**
   * Optional helper text to display below the select input.
   */
  helperText?: string;
  /**
   * A function that returns a string representation of an option.
   * @param option - The option to render.
   */
  placeholder?: SelectProps['placeholder'];
  optionViewer?: (option: Option) => string;
  /**
   * A function that returns the value of an option.
   * @param option - The option to render.
   */
  optionSelector?: (option: Option) => Value;
}

/**
 * A form field component that renders a select input using `react-hook-form` and `@mui/material`.
 *
 * @typeParam Option - The type of the options in the select input.
 * @typeParam Value - The type of the selected value in the select input.
 * @typeParam FormValues - The type of the form values object.
 *
 * @throws Throws an error if `options` is an array of objects and `optionViewer` is not provided.
 *
 * @returns  The `SelectField` component.
 */
export function SelectField<
  Option,
  Value extends string | number | readonly string[] | undefined,
  FormValues extends FieldValues,
>({
  options,
  name,
  label,
  control,
  helperText,
  placeholder,
  includeNone,
  optionViewer,
  optionSelector,
  ...props
}: SelectFieldProps<Option, Value, FormValues>) {
  const { t } = useTranslation();
  const { field, fieldState } = useController<FormValues>({
    name,
    control,
  });
  if (
    options.length &&
    typeof options[0] === 'object' &&
    optionViewer === undefined
  )
    throw new Error('optionViewer is required when option is an object.');
  return (
    <FormControl error={Boolean(fieldState.error)} {...props}>
      <InputLabel id={`${name}select-label`}>{label}</InputLabel>
      <Select
        label={label}
        labelId={`${name}select-label`}
        id={`${name}select`}
        placeholder={placeholder}
        renderValue={(selected) => {
          const optionValue = options.find((option) =>
            optionSelector
              ? optionSelector(option) === selected
              : option === selected,
          );
          if (optionValue)
            return optionViewer
              ? optionViewer(optionValue)
              : (optionValue as string);
        }}
        value={typeof field.value === 'object' ? '' : field.value}
        onChange={field.onChange}
      >
        {includeNone && (
          <MenuItem value="">
            <em>{t('none')}</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={
              optionViewer
                ? optionViewer(option)
                : (option as unknown as string)
            }
            value={
              optionSelector
                ? optionSelector(option)
                : (option as unknown as Value)
            }
          >
            {optionViewer ? optionViewer(option) : (option as string)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={Boolean(fieldState.error)}>
        {fieldState.error?.message || helperText}
      </FormHelperText>
    </FormControl>
  );
}

export default SelectField;
