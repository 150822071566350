import {
  FacebookSignIn,
  GoogleSignIn,
  SigninProcessStatus,
  TwitterSignIn,
} from '@livekatsomo/types';
import Facebook from '@mui/icons-material/Facebook';
// import Facebook from '@mui/icons-material/Facebook';
// import Facebook from '@mui/icons-material/Facebook';
import Google from '@mui/icons-material/Google';
// import Twitter from '@mui/icons-material/Twitter';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

/**
 * Props for the RegisterWithProviders component.
 */
export interface RegisterWithProvidersProps {
  /**
   * Callback function for Google sign-in.
   */
  onGoogleSignIn: GoogleSignIn;
  /**
   * Callback function for Facebook sign-in.
   */
  onFacebookSignIn: FacebookSignIn;
  /**
   * Callback function for Twitter sign-in.
   */
  onTwitterSignIn: TwitterSignIn;
  /**
   * Optional status of the sign-in process.
   */
  processing?: SigninProcessStatus;
}

/**
 * Renders a component that allows the user to register with various sign-in providers.
 *
 * @param {RegisterWithProvidersProps} props - The props for the component.
 * @param props.onGoogleSignIn - The function to call when the user clicks the "Register with Google" button.
 * @param props.onFacebookSignIn - The function to call when the user clicks the "Register with Facebook" button.
 * @param props.onTwitterSignIn - The function to call when the user clicks the "Register with Twitter" button.
 * @param props.processing - The ID of the sign-in provider that is currently being processed, or an empty string if no provider is being processed.
 * @returns The rendered component.
 */
export function RegisterWithProviders({
  onGoogleSignIn,
  onFacebookSignIn,
  onTwitterSignIn,
  processing,
}: RegisterWithProvidersProps) {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6" component="h2" align="center">
        {t('Register account using sign in providers')}
      </Typography>
      <LoadingButton
        fullWidth
        variant="outlined"
        loading={processing === 'google.com'}
        startIcon={<Google />}
        disabled={!!processing}
        onClick={onGoogleSignIn}
      >
        {t('Register with Google')}
      </LoadingButton>
      <LoadingButton
        fullWidth
        variant="outlined"
        loading={processing === 'facebook.com'}
        startIcon={<Facebook />}
        disabled={!!processing}
        onClick={onFacebookSignIn}
      >
        {t('Register with Facebook')}
      </LoadingButton>
      {/*<LoadingButton
        fullWidth
        variant="outlined"
        loading={processing === 'twitter.com'}
        startIcon={<Twitter />}
        disabled={!!processing}
        onClick={onTwitterSignIn}
      >
        {t('Register with Twitter')}
      </LoadingButton> */}
    </>
  );
}
