import { useTranslation } from 'next-i18next';

/**
 * A component that displays a general sign-in error message.
 * @param errorMessage - The error message to display.
 * @returns A JSX element that displays the error message.
 */
export function GeneralSignInError({ errorMessage }: { errorMessage: string }) {
  const { t } = useTranslation();
  return (
    <span>
      {t('Sign in was not possible.')} <em>{errorMessage}</em>
    </span>
  );
}
