import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

/**
 * Renders a component to display a message indicating that the user
 * has registered successfully and is loading user details.
 */
export function RegisteredSuccesfully() {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6">
        {t('Registered successfully. Loading user details.')}
      </Typography>
      <CircularProgress />
    </>
  );
}
