import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { SignInForm, SignInFormProps } from '../SignInForm/SignInForm';
import {
  SignInWithProviders,
  SignInWithProvidersProps,
} from '../SignInWithProviders/SignInWithProviders';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'next-i18next';

export type SigninViewProps = SignInFormProps & SignInWithProvidersProps;

/**
 * A component that displays a sign-in form and sign-in options using third-party providers.
 * @param props - The props for the component.
 * @returns A React component.
 */
export function SigninView(props: SigninViewProps) {
  const { t } = useTranslation();
  return (
    <Container component="main" maxWidth="xs">
      <Paper
        sx={{
          marginTop: 8,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: 2,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main', alignSelf: 'center' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5" component="h2" align="center">
          {t('Sign In using email and password')}
        </Typography>
        <SignInForm {...props} />
        <Divider>
          <Chip color="secondary" label={t('OR')} />
        </Divider>
        <SignInWithProviders {...props} />
      </Paper>
    </Container>
  );
}

export default SigninView;
