import { zodResolver } from '@hookform/resolvers/zod';
import { CreateUser, SigninProcessStatus } from '@livekatsomo/types';
import { Link } from '@livekatsomo/web/ui-components/link';
import { TextField } from '@livekatsomo/web/ui-components/react-hook-form-components';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Key';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';
import { ConsentSwitchButton } from '../components/ConsentSwitchButton';

/**
 * A Zod schema for validating the registration form fields.
 */
const formSchema = z
  .object({
    name: z.string().nonempty(),
    email: z.string().email().nonempty(),
    password: z.string().min(8),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ['confirmPassword'],
    params: {
      i18n: { key: 'Passwords must match' },
    },
  });

/**
 * Props for the RegisterForm component.
 */
export interface RegisterFormProps {
  errorMessage?: React.ReactNode;
  email?: string;
  isModal?: boolean;
  processing: SigninProcessStatus;
  onCreateUser: CreateUser;
  onSwitchToLogin?: (email?: string) => void;
  onClose?: () => void;
}

/**
 * A form component for registering a new user.
 *
 * @param onClose - Optional function to handle closing the form.
 * @param onCreateUser - Function to handle creating a new user.
 * @param onSwitchToLogin - Optional function to handle switching to the login form.
 * @param processing - The current status of the sign-in process.
 * @param errorMessage - Optional error message to display.
 * @param email - Optional default email value for the email field.
 * @param isModal - Optional boolean to indicate if the form is being used in a modal.
 */
export function RegisterForm({
  onClose,
  onCreateUser,
  onSwitchToLogin,
  processing,
  errorMessage,
  email: defaultEmail,
}: RegisterFormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();
  const [consent, setConsent] = React.useState(false);

  const { t } = useTranslation();
  z.setErrorMap(makeZodI18nMap({ t, handlePath: { ns: ['common', 'zod'] } }));

  const initialValues = {
    name: '',
    email: defaultEmail || '',
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
    // mode: 'onBlur',
  });

  const {
    formState: { isDirty, isSubmitting, isValid },
    reset,
    handleSubmit,
    watch,
  } = methods;

  const email = watch('email');

  function submit(values: typeof initialValues) {
    console.log('submit pressed');
    const { email, password, name } = values;
    onCreateUser(email, password, name);
  }

  const signInInsteadMessage = t('Already have account? Sign in instead.');
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} onReset={() => reset()}>
        <TextField
          name="name"
          margin="normal"
          required
          fullWidth
          data-test="name-field"
          label={t('Name')}
          placeholder={t('Enter your name') || 'Enter your name'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }} // autoFocus
        />
        <TextField
          name="email"
          margin="normal"
          required
          fullWidth
          data-test="email-field"
          label={t('Email Address')}
          autoComplete="email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="password"
          margin="normal"
          required
          fullWidth
          data-test="password-field"
          label={t('Password')}
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PasswordIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="confirmPassword"
          margin="normal"
          required
          fullWidth
          data-test="confirm-password-field"
          label={t('Confirm Password')}
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PasswordIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={
            <Switch
              value={showPassword}
              onChange={(e, checked) => setShowPassword(checked)}
            />
          }
          label={t('Show password')}
        />
        <Box sx={{ color: (theme) => theme.palette.error.main }}>
          {errorMessage}
        </Box>
        <ConsentSwitchButton
          consent={consent}
          setConsent={setConsent}
        ></ConsentSwitchButton>
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          {onClose ? (
            <Button onClick={onClose} type="button">
              {t('Cancel')}
            </Button>
          ) : null}

          <Button type="reset" disabled={isSubmitting || !isDirty}>
            {t('Reset')}
          </Button>
        </Box>
        <LoadingButton
          type="submit"
          fullWidth
          data-test="register-button"
          variant="contained"
          sx={{
            mb: 2,
          }}
          disabled={
            isSubmitting || !isValid || !consent || !isDirty || !!processing
          }
          loading={isSubmitting}
        >
          {t('Register')}
        </LoadingButton>
        <Grid container>
          <Grid item>
            {onSwitchToLogin ? (
              <Button variant="text" onClick={() => onSwitchToLogin(email)}>
                {signInInsteadMessage}
              </Button>
            ) : (
              <Link
                href={{
                  pathname: '/sign-in',
                  query: { ...router.query },
                }}
                variant="body2"
              >
                {signInInsteadMessage}
              </Link>
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
