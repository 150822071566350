import { UserAlreadyRegisteredWithOtherProviderError } from '@livekatsomo/custom-errors';
import {
  GeneralSignUpError,
  PopupClosedError,
  UnknowUserError,
  UserAlreadyExistsError,
  WeakPasswordError,
  WrongPasswordError,
} from '@livekatsomo/web/ui-components/error-messages';
import { AuthError, AuthErrorCodes } from 'firebase/auth';
import { useRouter } from 'next/router';
import { UserAlreadyRegisteredWithOtherMethodError } from './UserAlreadyRegisteredWithOtherMethodError';

/**
 * A component that displays an error message when there is an authentication error.
 * @param props - The component props.
 * @param props.error - The error object to display.
 * @returns The JSX element to render or null if there is no error.
 */
export function AuthenticationErrorViewer({
  error,
  email,
  onSwitchToLogin,
}: {
  error?: Error | null;
  email?: string | null;
  onSwitchToLogin?: (email?: string) => void;
}) {
  const router = useRouter();
  if (!error) return null;

  const authError = error as AuthError;
  const errorCode = authError.code;
  const { redirectTo } = router.query as { redirectTo: string };
  console.log(error);

  let ErrorMessage: React.ReactNode = null;

  switch (errorCode) {
    case 'auth/invalid-credential':
      ErrorMessage = <UnknowUserError email={email} redirectTo={redirectTo} />;
      break;
    case AuthErrorCodes.USER_DELETED:
      ErrorMessage = <UnknowUserError email={email} redirectTo={redirectTo} />;
      break;
    case AuthErrorCodes.INVALID_PASSWORD:
      ErrorMessage = (
        <WrongPasswordError email={email} redirectTo={redirectTo} />
      );
      break;
    case AuthErrorCodes.EMAIL_EXISTS:
      ErrorMessage = <UserAlreadyExistsError email={email} />;
      break;
    case AuthErrorCodes.WEAK_PASSWORD:
      ErrorMessage = <WeakPasswordError />;
      break;
    case AuthErrorCodes.POPUP_CLOSED_BY_USER:
      ErrorMessage = <PopupClosedError />;
      break;

    case AuthErrorCodes.NEED_CONFIRMATION: {
      const {
        data: { provider, loginMethods },
      } = error as UserAlreadyRegisteredWithOtherProviderError;
      ErrorMessage = (
        <UserAlreadyRegisteredWithOtherMethodError
          loginMethods={loginMethods}
          email={email}
          provider={provider}
        />
      );
      break;
    }

    default:
      ErrorMessage = <GeneralSignUpError errorMessage={error.message} />;
      break;
  }

  if (error) return ErrorMessage;
  return null;
}
