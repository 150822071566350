import { Link } from '@livekatsomo/web/ui-components/link';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import { useRouter } from 'next/router';

export interface UserAlreadyExistsErrorProps {
  email?: string | null;
  onSwitchToLogin?: (email?: string | null) => void;
}

/**
 * Renders an error message indicating that a user with the provided email or account already exists.
 * @param email The email of the existing user, if available.
 * @returns The error message component.
 */
export function UserAlreadyExistsError({
  email,
  onSwitchToLogin,
}: UserAlreadyExistsErrorProps) {
  const { t } = useTranslation();
  const router = useRouter();

  if (email)
    return (
      <span>{t('User with email: {{email}} already exists.', { email })}</span>
    );
  else {
    return (
      <span>
        {t('User with account already exists.')}
        {onSwitchToLogin ? (
          <Button variant="text" onClick={() => onSwitchToLogin(email)}>
            {t('Sign in instead.')}
          </Button>
        ) : (
          <Link
            href={{
              pathname: '/sign-in',
              query: { ...router.query },
            }}
            variant="body2"
          >
            {t('Sign in instead.')}
          </Link>
        )}
      </span>
    );
  }
}
