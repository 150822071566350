import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { RegisterForm, RegisterFormProps } from '../RegisterForm/RegisterForm';
import {
  RegisterWithProviders,
  RegisterWithProvidersProps,
} from '../RegisterWithProviders/RegisterWithProviders';
import { useTranslation } from 'next-i18next';

export type RegisterProps = RegisterFormProps & RegisterWithProvidersProps;

export function Register(props: RegisterProps) {
  const { t } = useTranslation();
  return (
    <Container component="main" maxWidth="xs">
      <Paper
        sx={{
          marginTop: 8,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: 2,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main', alignSelf: 'center' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('Register using email and password')}
        </Typography>

        <RegisterForm {...props}></RegisterForm>
        <Divider>
          <Chip color="secondary" label={t('OR')} />
        </Divider>
        <RegisterWithProviders {...props} />
      </Paper>
    </Container>
  );
}

export default Register;
