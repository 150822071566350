import {
  Breadcrumbs,
  Footer,
  Header,
} from '@livekatsomo/web/ui-components/layout';
import Container from '@mui/material/Container';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { RecoverEmail, RecoverEmailProps } from '../RecoverEmail/RecoverEmail';
import {
  ResetPassword,
  ResetPasswordProps,
} from '../ResetPassword/ResetPassword';
import { VerifyEmail, VerifyEmailProps } from '../VerifyEmail/VerifyEmail';

interface ActionHandlerQuery extends ParsedUrlQuery {
  mode: 'resetPassword' | 'verifyEmail' | 'recoverEmail';
  oobCode: string;
  apiKey: string;
  continueUrl: string;
  lang: string;
}

/**
 * Props for the ActionHandlerView component.
 * This component is used to pass down props to the RecoverEmail, ResetPassword, and VerifyEmail components.
 * @property RecoverEmailProps - Props for the RecoverEmail component.
 * @property ResetPasswordProps - Props for the ResetPassword component.
 * @property VerifyEmailProps - Props for the VerifyEmail component.
 */
export type ActionHandlerViewProps = Omit<
  RecoverEmailProps,
  'oobCode' | 'lang'
> &
  Omit<ResetPasswordProps, 'oobCode' | 'lang' | 'continueUrl'> &
  Omit<VerifyEmailProps, 'oobCode' | 'lang' | 'continueUrl'>;

/**
 * Renders a view for handling different actions related to authentication, such as password reset, email verification, and email recovery.
 * @param applyActionCode Function to apply an action code.
 * @param checkActionCode Function to check an action code.
 * @param sendPasswordResetEmail Function to send a password reset email.
 * @param verifyPasswordResetCode Function to verify a password reset code.
 * @param confirmPasswordReset Function to confirm a password reset.
 * @param signInWithEmailAndPassword Function to sign in with email and password.
 * @param profileUpdatedCallable Function to handle profile updates.
 * @param requestEmailVerification Function to request email verification.
 * @returns A React component that renders the appropriate view based on the action specified in the URL query parameters.
 */
export function ActionHandlerView({
  applyActionCode,
  checkActionCode,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
  profileUpdatedCallable,
  requestEmailVerification,
}: ActionHandlerViewProps) {
  const router = useRouter();
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mode, oobCode, apiKey, continueUrl, lang } =
    router.query as ActionHandlerQuery;

  let node = null;
  let title = '';

  switch (mode) {
    case 'resetPassword':
      node = (
        <ResetPassword
          oobCode={oobCode}
          continueUrl={continueUrl}
          lang={lang}
          verifyPasswordResetCode={verifyPasswordResetCode}
          confirmPasswordReset={confirmPasswordReset}
          signInWithEmailAndPassword={signInWithEmailAndPassword}
        />
      );
      title = t('Password reset');
      break;
    case 'verifyEmail':
      node = (
        <VerifyEmail
          oobCode={oobCode}
          lang={lang}
          continueUrl={continueUrl}
          user={null}
          applyActionCode={applyActionCode}
          profileUpdatedCallable={profileUpdatedCallable}
          requestEmailVerification={requestEmailVerification}
        />
      );
      title = t('Verify email');
      break;
    case 'recoverEmail':
      node = (
        <RecoverEmail
          oobCode={oobCode}
          lang={lang}
          user={null}
          checkActionCode={checkActionCode}
          applyActionCode={applyActionCode}
          profileUpdatedCallable={profileUpdatedCallable}
          sendPasswordResetEmail={sendPasswordResetEmail}
        />
      );
      title = t('Recover email');
      break;
  }

  return (
    <>
      <Header title={title} />
      <Breadcrumbs sx={{ p: 1 }} />
      <Container
        maxWidth="md"
        component="main"
        sx={{
          my: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
        }}
      >
        {node}
      </Container>
      <Footer />
    </>
  );
}

export default ActionHandlerView;
