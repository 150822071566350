import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useCallback, useMemo, useState } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from 'react-hook-form';

/**
 * Props for the AutoCompleteField component.
 * @typeParam TFormValues The type of form values.
 * @typeParam TOption The type of options for the autocomplete field.
 * @property {Path<TFormValues>} name The name of the field.
 * @property {Control<TFormValues>} [control] Optional control for the field.
 */
export interface AutoCompleteFieldProps<
  TFormValues extends FieldValues,
  TOption extends string | object = string,
> extends Omit<AutocompleteProps<TOption, false, false, false>, 'renderInput'> {
  /**
   * The name of the field.
   */
  name: FieldPath<TFormValues>;
  /**
   * The control for the field.
   */
  control?: Control<TFormValues>;
  /**
   * The options for the autocomplete field.
   */
  options: TOption[];
  /**
   * The function to get the label for the option.
   */
  getOptionLabel?: (option: NoInfer<TOption>) => string;
  /**
   * The function to get the value for the option.
   */
  getOptionValue: (option: NoInfer<TOption>) => string;
}

/**
 * A component that provides an autocomplete input field with options.
 * @typeParam TFormValues - The type of form values.
 * @typeParam TOption - The type of options.
 * @returns  - The AutoCompleteField component.
 */
export function AutoCompleteField<
  TOption extends { id: string; name: string },
  TFormValues extends FieldValues = FieldValues,
>({
  name,
  control,
  options,
  getOptionLabel = (option: TOption) =>
    typeof option === 'string' ? option : '',
  getOptionValue,
  disabled,
  label,
  helperText,
  variant,
  required,
  ...rest
}: AutoCompleteFieldProps<TFormValues, TOption> & TextFieldProps) {
  const {
    field: { onChange: onChangeField, value: fieldValue },
    fieldState: { error },
  } = useController<TFormValues>({ name, control });

  const onChange = useCallback<
    NonNullable<AutocompleteProps<TOption, false, false, false>['onChange']>
  >(
    (e, value, reason) => {
      onChangeField(value ? getOptionValue(value) : null);
    },
    [getOptionValue, onChangeField],
  );

  /**
   * The current selected option from the form value (usually object id)
   * The AutoComplete component works on the object itself, not the id, so we need to find the object from the id.
   */
  const value = useMemo(
    () =>
      options.find(
        (option: TOption) => getOptionValue(option) === fieldValue,
      ) ?? null,
    [options, getOptionValue, fieldValue],
  );
  const [inputValue, setInputValue] = useState<string>(
    value ? getOptionLabel(value) : '',
  );

  const handleChange = useCallback<
    NonNullable<AutocompleteProps<TOption, false, false, false>['onChange']>
  >(
    (e, newValue, reason) => {
      onChange?.(e, newValue, reason);
      setInputValue(newValue ? getOptionLabel(newValue) : '');
    },
    [getOptionLabel, onChange],
  );

  return (
    <Autocomplete
      options={options}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      autoHighlight
      clearOnBlur
      clearOnEscape
      onInputChange={(e, newInputValue, reason) => {
        setInputValue(newInputValue);
      }}
      onBlur={() => {
        setInputValue(value ? getOptionLabel(value) : '');
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            disabled={disabled}
            error={!!error}
            helperText={error?.message || helperText}
            label={label}
            variant={variant}
            required={required}
          />
        );
      }}
      {...rest}
    />
  );
}

export default AutoCompleteField;
