import Upload from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Accept, useDropzone } from 'react-dropzone';
import { alpha } from '@mui/material/styles';
import { FileUploadProgess } from '@livekatsomo/types';
import { UploadListItem } from '@livekatsomo/web/ui-components/upload-list-item';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

/**
 * Props for the FileUploadButtonField component.
 * @typeParam TFormValues The type of form values.
 */
export interface FileUploadButtonFieldProps<
  TFormValues extends FieldValues = FieldValues,
> {
  /**
   * The name of the field.
   */
  name: Path<TFormValues>;
  /**
   * The control object of the form.
   */
  control?: Control<TFormValues>;
  /**
   * The label of the field.
   */
  label?: string;
  /**
   * The file types accepted by the field.
   */
  accept?: Accept;
  /**
   * The function to track the upload progress of the file.
   */
  uploadProgress: FileUploadProgess;
}

/**
 * A file upload button component that integrates with `react-hook-form` and `react-dropzone`.
 *
 * @typeParam TFormValues The type of form values.
 *
 * @param props The component props.
 * @param props.name The name of the field in the form.
 * @param {import('react-hook-form').Control<TFormValues>} [props.control] The `react-hook-form` control object.
 * @param [props.label] The label for the button.
 * @param {string | string[]} [props.accept] The file types to accept.
 * @param {import('@livekatsomo/types').FileUploadProgess} props.uploadProgress The upload progress object.
 *
 * @returns  The component JSX.
 */
export function FileUploadButtonField<
  TFormValues extends FieldValues = FieldValues,
>({
  name,
  control,
  label,
  accept,
  uploadProgress,
}: FileUploadButtonFieldProps<TFormValues>) {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController<TFormValues>({
    name,
    control,
  });
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept,
      multiple: false,
      noClick: true,
      onDrop: (acceptedFiles) => {
        field.onChange(acceptedFiles);
      },
    });

  const progress =
    Array.isArray(field.value) && field.value.length && uploadProgress
      ? uploadProgress[field.value[0]?.name]?.progress
      : 0;
  const uploadError =
    Array.isArray(field.value) && field.value.length && uploadProgress
      ? uploadProgress[field.value[0]?.name]?.error
      : undefined;

  return (
    <Box {...getRootProps()} sx={{ position: 'relative' }}>
      {field.value && Array.isArray(field.value) && field.value.length ? (
        <UploadListItem
          key={field.value[0].name}
          file={field.value[0]}
          progress={progress || 0}
          error={uploadError || error?.message}
        />
      ) : null}
      <Button
        color="secondary"
        variant="contained"
        fullWidth
        component="label"
        startIcon={<Upload />}
      >
        {label || t('Upload file')}
        <input {...getInputProps()} />
      </Button>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          textAlign: 'center',
          display: isDragActive ? 'flex' : 'none',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: isDragReject
            ? (theme) => alpha(theme.palette.error.main, 0.8)
            : (theme) => alpha(theme.palette.grey[900], 0.8),
          color: 'common.white',
        }}
      >
        <Typography color="grey.100" variant="h4">
          {isDragReject ? t('Filetype not accepted') : t('Drop here')}
        </Typography>
        <Upload fontSize="large" />
      </Box>
      {error ? (
        <Typography color="error" variant="body2">
          {error?.message}
        </Typography>
      ) : null}
    </Box>
  );
}
