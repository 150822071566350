import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

/**
 * Component that displays an error message for unauthorized users.
 * @param props Props for the component.
 * @returns The UnauthorizedUserErrorView component.
 */
export function UnauthorizedUserErrorView() {
  const { t } = useTranslation();
  return (
    <Container
      maxWidth="md"
      component="main"
      sx={{ textAlign: 'center', my: 4 }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        {t('Your account is not authorized to access this page')}
      </Typography>
    </Container>
  );
}

export default UnauthorizedUserErrorView;
