import { FieldValues, Path, useController } from 'react-hook-form';
import { AssetSelect, AssetSelectProps } from './asset-select/AssetSelect';

/**
 * Props for the AssetSelectField component.
 * @typeParam TFormValues The type of form values.
 */
export interface AssetSelectFieldProps<
  TFormValues extends FieldValues = FieldValues,
> extends Omit<AssetSelectProps, 'onChange' | 'value'> {
  /** The name of the field in the form. */
  name: Path<TFormValues>;
}

/**
 * Renders a form field with an asset select component.
 * @typeParam TFormValues The type of form values.
 * @returns  The JSX element for the component.
 */
export function AssetSelectField<
  TFormValues extends FieldValues = FieldValues,
>({ name, ...props }: AssetSelectFieldProps<TFormValues>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
  });

  return (
    <AssetSelect
      value={field.value}
      onChange={field.onChange}
      errorMesssage={error?.message}
      {...props}
    />
  );
}

export default AssetSelectField;
