import { AssetDoc } from '@livekatsomo/models';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';

/**
 * Props for the AssetAutocompleteSelectField component.
 * @typeParam TFormValues The type of form values.
 */
export interface AssetAutocompleteSelectFieldProps<
  TFormValues extends FieldValues,
> {
  /**
   * The name of the field in the form.
   */
  name: Path<TFormValues>;
  /**
   * An array of assets to be displayed in the autocomplete select field.
   */
  assets: AssetDoc[];
  /**
   * The label to be displayed for the field.
   */
  label?: ReactNode;
}
export interface AssetAutocompleteSelectFieldProps<
  TFormValues extends FieldValues,
> {
  name: Path<TFormValues>;
  assets: AssetDoc[];
  label?: ReactNode;
}

/**
 * A component that renders an autocomplete select field for assets.
 *
 * @typeParam TFormValues The type of form values.
 *
 * @param props The component props.
 * @param props.name The name of the field in the form.
 * @param {AssetDoc[]} props.assets The list of assets to display in the autocomplete.
 * @param {ReactNode} [props.label] The label to display for the field.
 *
 * @returns  The JSX element for the component.
 */
export function AssetAutocompleteSelectField<TFormValues extends FieldValues>({
  name,
  assets,
  label,
}: AssetAutocompleteSelectFieldProps<TFormValues>) {
  const { t } = useTranslation();
  const { field } = useController<TFormValues>({ name });

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={assets}
      value={field.value}
      freeSolo
      onChange={(event, newValue) => {
        field.onChange(newValue || '');
      }}
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.filename
      }
      isOptionEqualToValue={(option, value) => {
        return option.id === value?.id;
      }}
      renderOption={(props, option) =>
        option ? (
          <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
            key={option.filename}
          >
            <img
              key={option.filename}
              loading="lazy"
              width="20"
              src={option.downloadUrls?.jpeg200x200 || option.originalUrl}
              alt=""
            />
            {option.filename}
          </Box>
        ) : null
      }
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.id}
            label={option.filename}
          />
        ));
      }}
      renderInput={(params) => (
        <TextField {...params} label={label || t('Choose a asset')} />
      )}
    />
  );
}
