import Upload from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import { ReactNode, forwardRef } from 'react';
import { AssetInDocument } from '@livekatsomo/models';
import { OverlayProgress } from '@livekatsomo/web/ui-components/overlay-progress';
import { useTranslation } from 'next-i18next';

/**
 * Props for the ImageUploadButton component.
 */
export interface ImageUploadButtonProps {
  /**
   * The current value of the component. Can be an array of File objects or an AssetInDocument object.
   */
  value: File[] | AssetInDocument;
  /**
   * The label to display for the component.
   */
  label?: ReactNode;
  /**
   * The current upload progress of the component, as a number between 0 and 1.
   */
  uploadProgress: number;
  /**
   * The variant of the button to display.
   */
  variant?: ButtonProps['variant'];
  /**
   * The children to display within the component.
   */
  children?: React.ReactNode;
  /**
   * Whether the component is required or not.
   */
  required?: boolean;
}

/**
 * A button component that allows users to upload an image.
 *
 * @param value - The value of the uploaded image.
 * @param label - The label of the button.
 * @param uploadProgress - The upload progress of the image.
 * @param variant - The variant of the button.
 * @param required - Whether the button is required or not.
 * @param children - The children of the button.
 * @returns A button component that allows users to upload an image.
 */
export const ImageUploadButton = forwardRef<
  HTMLLabelElement,
  ImageUploadButtonProps
>(function ImageUploadButton(
  {
    value,
    label,
    uploadProgress,
    variant = 'contained',
    required,
    children,
  }: ImageUploadButtonProps,
  ref,
) {
  const { t } = useTranslation();
  if (Array.isArray(value) && value.length > 0) {
    return (
      <Button ref={ref} variant={variant} component="label" fullWidth>
        <Box
          component="img"
          sx={{
            objectFit: 'contain',
            height: 200,
            maxWidth: '100%',
          }}
          src={URL.createObjectURL(value[0])}
          alt="logo"
        />
        {children}
        <OverlayProgress uploadProgress={uploadProgress || 0} />
      </Button>
    );
  }
  if (!Array.isArray(value) && value) {
    return (
      <Button ref={ref} fullWidth component="label" variant={variant}>
        <Box
          component="img"
          sx={{
            objectFit: 'contain',
            height: 200,
            maxWidth: '100%',
          }}
          src={
            (Array.isArray(value) &&
              value.length &&
              URL.createObjectURL(value[0])) ||
            value.downloadUrls?.png200x200 ||
            value.originalUrl
          }
          alt="logo"
        />
        {children}
      </Button>
    );
  } else {
    return (
      <Button
        ref={ref}
        color="secondary"
        variant={variant}
        fullWidth
        component="label"
        startIcon={<Upload />}
        sx={{
          ...(required && {
            '&::after': {
              content: '" * "',
              marginLeft: 1 / 2,
            },
          }),
        }}
      >
        {label || t('Upload image')}
        {children}
      </Button>
    );
  }
});
