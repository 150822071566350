import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

/**
 * Renders an error message when an event with the given slug is not found.
 * @param slug - The slug of the event that was not found.
 * @returns The JSX element that renders the error message.
 */
export function EventNotFoundError({ slug }: { slug: string }): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="h3"
        component="h1"
        sx={{
          textAlign: 'center',
        }}
      >
        {t(`Event by name {{slug}} is not found.`, { slug })}
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        sx={{
          textAlign: 'center',
        }}
      >
        {t('Please check the name and try again.')}
      </Typography>
    </>
  );
}
