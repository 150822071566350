import { Link } from '@livekatsomo/web/ui-components/link';
import { useTranslation } from 'next-i18next';

export interface UnknowUserErrorProps {
  email?: string | null;
  redirectTo?: string;
}

/**
 * Displays an error message when a user account is not found and provides a link to sign up.
 * @param email - The email of the user, if available.
 * @param redirectTo - The URL to redirect to after signing up.
 * @returns A React component that displays the error message and sign up link.
 */
export function UnknowUserError({ email, redirectTo }: UnknowUserErrorProps) {
  const { t } = useTranslation();
  return (
    <span data-test="user-account-not-found-error">
      {t("User account not found. Don't have account yet?", {
        nsSeparator: '-:-',
      })}
      {` `}
      <Link
        data-test="sign-up-link-from-unknown-user-error"
        href={{ pathname: '/register', query: { redirectTo, email } }}
        variant="body2"
      >
        {t('Sign up')}
      </Link>
    </span>
  );
}
