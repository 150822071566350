import FormControl, { FormControlProps } from '@mui/material/FormControl';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

/**
 * Props for the RadioField component.
 *
 * @typeParam TFormValues The type of form values.
 * @typeParam Option The type of options.
 * @typeParam Value The type of the selected value.
 */
export interface RadioFieldProps<
  TFormValues extends FieldValues,
  Option,
  Value extends string | number | readonly string[] | undefined,
> extends FormControlProps {
  /** The name of the field. */
  name: Path<TFormValues>;
  /** The React Hook Form control object. */
  control?: Control<TFormValues>;
  /** The array of options to display. */
  options: Option[];
  /** The label for the radio button group. */
  label: string;
  /** The default value for the radio button group. */
  defaultValue?: string;
  /** A function to transform the option into a string for display. */
  optionViewer?: (option: Option) => string;
  /** A function to select the value of the option. */
  optionSelector?: (option: Option) => Value;
}

/**
 * A radio button group component for use with React Hook Form.
 * @typeParam TFormValues - The type of the form values object.
 * @typeParam Option - The type of the options array elements.
 * @typeParam Value - The type of the selected value.
 * @returns  - The rendered RadioField component.
 */
export function RadioField<
  Option,
  Value extends string | number | readonly string[] | undefined,
  TFormValues extends FieldValues,
>({
  name,
  control,
  options,
  label,
  defaultValue,
  optionViewer,
  optionSelector,
  sx,
  ...props
}: RadioFieldProps<TFormValues, Option, Value>) {
  const { field } = useController<TFormValues>({
    name,
    control,
  });
  const id = `${name}-radio-buttons-group-label`;
  return (
    <FormControl {...props}>
      <FormLabel id={id}>{label}</FormLabel>
      <RadioGroup
        aria-labelledby={id}
        defaultValue={defaultValue}
        {...field}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          ...sx,
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={
              optionViewer
                ? optionViewer(option)
                : (option as unknown as string)
            }
            value={
              optionSelector
                ? optionSelector(option)
                : (option as unknown as Value)
            }
            control={<Radio />}
            label={optionViewer ? optionViewer(option) : (option as string)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioField;
