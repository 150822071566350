import { useTranslation } from 'next-i18next';

/**
 * Renders an error message indicating that the password is weak.
 * @returns A React component that displays the error message.
 */
export function WeakPasswordError() {
  const { t } = useTranslation();
  return <span>{t('Password should be at least 6 characters.')}</span>;
}
