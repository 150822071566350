import { useTranslation } from 'next-i18next';

export interface WrongPasswordErrorProps {
  email?: string | null;
  redirectTo?: string;
}

/**
 * Renders an error message indicating that the password entered is invalid.
 * @param email - The email address associated with the account, if available.
 * @param redirectTo - The URL to redirect to after displaying the error message.
 * @returns A React component that displays the error message.
 */
export function WrongPasswordError({
  email,
  redirectTo,
}: WrongPasswordErrorProps) {
  const { t } = useTranslation();
  return <span>{t('Invalid password.')}</span>;
}
