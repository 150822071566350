import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * Props for the OverlayProgress component.
 */
export interface OverlayProgressProps {
  uploadProgress: number;
}

/**
 * OverlayProgress component displays a circular progress bar on top of the content.
 * @param {OverlayProgressProps} props - The props for the component.
 * @param {number} props.uploadProgress - The progress of the upload as a number between 0 and 100.
 * @returns  - A React component that displays a circular progress bar.
 */
export function OverlayProgress({ uploadProgress }: OverlayProgressProps) {
  if (!uploadProgress) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        textAlign: 'center',
        display: uploadProgress ? 'flex' : 'none',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'common.white',
      }}
    >
      <CircularProgress
        color="secondary"
        variant="determinate"
        value={uploadProgress}
      />
    </Box>
  );
}

export default OverlayProgress;
