import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { User } from '@livekatsomo/models';
import {
  ApplyActionCode,
  ProfileUpdatedCallable,
  RequestEmailVerification,
} from '@livekatsomo/types';
import { useTranslation } from 'next-i18next';
import { EmailVerified } from '../EmailVerified/EmailVerified';
import { VerifyEmailExpiredOrInvalidCode } from '../VerifyEmailExpiredOrInvalidCode/VerifyEmailExpiredOrInvalidCode';

/**
 * Props for the VerifyEmail component.
 */
export interface VerifyEmailProps {
  user: User | null;
  oobCode: string;
  lang: string;
  continueUrl: string;
  applyActionCode: ApplyActionCode;
  profileUpdatedCallable: ProfileUpdatedCallable;
  requestEmailVerification: RequestEmailVerification;
}

/**
 * VerifyEmail component verifies the email address of a user by applying the email verification code.
 * @param requestEmailVerification - Function to request email verification.
 * @param applyActionCode - Function to apply the email verification code.
 * @param user - User object containing email and uid.
 * @param profileUpdatedCallable - Function to update possible pre-authorized roles.
 * @param oobCode - Email verification code.
 * @param continueUrl - URL to redirect the user back to the app.
 * @param lang - Language parameter to localize the UI.
 * @returns EmailVerified component if the email is verified, VerifyEmailExpiredOrInvalidCode component if the code is invalid or expired, and a loading spinner while verifying the email.
 */
export function VerifyEmail({
  requestEmailVerification,
  applyActionCode,
  user,
  profileUpdatedCallable,
  oobCode,
  continueUrl,
  lang,
}: VerifyEmailProps) {
  const [codeVerified, setCodeVerified] = useState(false);
  const [verifyEmailError, setVerifyEmailError] = useState(false);

  const { t } = useTranslation();

  // Localize the UI to the selected language as determined by the lang
  // parameter.
  // Try to apply the email verification code.
  const { email, uid } = user || {};

  useEffect(() => {
    applyActionCode(oobCode)
      .then(() => {
        // Email address has been verified.
        // You could also provide the user with a link back to the app.
        // Call profileUpdatedCallable function callback. To update possible pre-authorized roles.
        setCodeVerified(true);
        uid && profileUpdatedCallable(uid);
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        setVerifyEmailError(error);
      });
  }, [
    applyActionCode,
    continueUrl,
    oobCode,
    requestEmailVerification,
    email,
    uid,
    profileUpdatedCallable,
  ]);

  if (verifyEmailError)
    return (
      <VerifyEmailExpiredOrInvalidCode
        email={email}
        onResend={requestEmailVerification}
      />
    );

  if (!codeVerified)
    return (
      <>
        <Typography variant="h5">{t('Verifying email')}</Typography>
        <CircularProgress />
      </>
    );

  return <EmailVerified continueUrl={continueUrl} />;
}
