import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

/**
 * Renders an error message for when a customer is not found.
 * @param slug - The slug of the customer that was not found.
 * @returns The JSX element for the error message.
 */
export function CustomerNotFoundError({ slug }: { slug: string }) {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="h3"
        component="h1"
        sx={{
          textAlign: 'center',
        }}
      >
        {t('Channel by name {{slug}} is not found.', { slug })}
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        sx={{
          textAlign: 'center',
        }}
      >
        {t('Please check the name and try again.')}
      </Typography>
    </>
  );
}
