import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import {
  ApplyActionCode,
  CheckActionCode,
  ProfileUpdatedCallable,
  SendPasswordResetEmail,
} from '@livekatsomo/types';
import { User } from '@livekatsomo/models';
import { useTranslation } from 'next-i18next';

/**
 * Props for the RecoverEmail component.
 */
export interface RecoverEmailProps {
  user: User | null;
  oobCode: string;
  lang: string;
  checkActionCode: CheckActionCode;
  applyActionCode: ApplyActionCode;
  profileUpdatedCallable: ProfileUpdatedCallable;
  sendPasswordResetEmail: SendPasswordResetEmail;
}

/**
 * RecoverEmail component displays a confirmation message to the user after their email has been restored.
 * @param user - The user object containing the user's information.
 * @param oobCode - The out-of-band code used to restore the user's email.
 * @param lang - The language used to localize the UI.
 * @param checkActionCode - A function that checks if the action code is valid.
 * @param applyActionCode - A function that reverts the account email to the restored email.
 * @param profileUpdatedCallable - A function that updates the user's profile.
 * @param sendPasswordResetEmail - A function that sends a password reset email to the user.
 * @returns A React component that displays a confirmation message to the user after their email has been restored.
 */
export function RecoverEmail({
  oobCode,
  lang,
  user,
  checkActionCode,
  applyActionCode,
  profileUpdatedCallable,
  sendPasswordResetEmail,
}: RecoverEmailProps) {
  const [codeVerified, setCodeVerified] = useState(false);
  const [restoredEmail, setRestoredEmail] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let restoredEmail: string;
    // Confirm the action code is valid.
    checkActionCode(oobCode)
      .then((info) => {
        // Get the restored email address.
        restoredEmail = info['data']['email'] as string;

        // Revert to the old email.
        return applyActionCode(oobCode);
      })
      .then(() => {
        // Account email reverted to restoredEmail
        // Display a confirmation message to the user.
        setRestoredEmail(restoredEmail);
        setCodeVerified(true);
        user && profileUpdatedCallable(user.uid);
        // TODO: You might also want to give the user the option to reset their password
        // in case the account was compromised:
      })
      .catch((error) => {
        // Invalid code.
        console.log(error);
      });
  }, [
    applyActionCode,
    checkActionCode,
    oobCode,
    profileUpdatedCallable,
    sendPasswordResetEmail,
    user,
  ]);

  if (!codeVerified)
    return (
      <>
        <Typography variant="h5">{t('Recovering email')}</Typography>
        <CircularProgress />
      </>
    );

  return (
    <Typography variant="h5">
      {t('Email restored to')} <b>{restoredEmail}</b>
    </Typography>
  );
}
