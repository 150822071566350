import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'next-i18next';

/**
 * Props for the ConsentSwitchButton component.
 */
export interface ConsentSwitchButtonProps {
  /**
   * The current consent value.
   */
  consent: boolean;
  /**
   * A function to set the consent value.
   * @param checked - The new consent value.
   */
  setConsent: (checked: boolean) => void;
}

/**
 * Renders a switch button that allows the user to consent to receiving transactional emails from the website.
 * @param props - The props for the ConsentSwitchButton component.
 * @param props.consent - A boolean value indicating whether the user has given consent to receiving transactional emails.
 * @param props.setConsent - A function that sets the consent value when the switch button is toggled.
 * @returns A React component that renders a switch button for consenting to transactional emails.
 */
export function ConsentSwitchButton(props: ConsentSwitchButtonProps) {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            value={props.consent}
            onChange={(event, checked) => props.setConsent(checked)}
          />
        }
        label={t(
          'I consent to receiving transactional emails from this website.',
        )}
      />
    </FormGroup>
  );
}
