import { Method } from '@livekatsomo/types';
import { useRouter } from 'next/router';
import Button from '@mui/material/Button';
import { useAuthentication } from '@livekatsomo/web/data-hooks';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  User,
} from 'firebase/auth';

export interface UserAlreadyRegisteredWithOtherMethodErrorProps {
  /**
   * The email address that is already registered.
   */
  email?: string | null;
  /**
   * The ID of the authentication provider that the user tried to register with.
   */
  provider: GoogleAuthProvider | FacebookAuthProvider | TwitterAuthProvider;
  /**
   * An array of authentication methods that the user can use to sign in with the existing account.
   */
  loginMethods: Method[];
}

/**
 * Renders a component that displays an error message when a user tries to register with an email that is already registered with a different authentication method.
 * Allows the user to sign in with the existing account using the same authentication method or with a different email and password.
 * @param props - The component props.
 * @param props.email - The email address that is already registered.
 * @param props.providerId - The ID of the authentication provider that the user tried to register with.
 * @param props.loginMethods - An array of authentication methods that the user can use to sign in with the existing account.
 * @returns  The JSX element that displays the error message and sign-in options.
 */
export function UserAlreadyRegisteredWithOtherMethodError({
  email,
  provider,
  loginMethods,
}: UserAlreadyRegisteredWithOtherMethodErrorProps) {
  const router = useRouter();
  const { t } = useTranslation();
  const {
    auth,
    linkWithProvider,
    googleSignIn,
    facebookSignIn,
    twitterSignIn,
  } = useAuthentication();

  const handleSignInWithExistingAccount = useCallback(
    async (signInMethod: () => Promise<User>) => {
      await signInMethod();
      const currentUser = auth.currentUser;
      if (!currentUser) return;
      await linkWithProvider(currentUser, provider);
    },
    [auth.currentUser, linkWithProvider, provider],
  );

  return (
    <div>
      <p className="mb-4 text-2xl font-bold">
        {t('There is already user account with email:')} <b>{email}</b>
      </p>
      <p>{t('Sign in with your existing user account?')}</p>
      <div className="mt-4 flex gap-2">
        {loginMethods.map((loginMethod) => {
          switch (loginMethod) {
            case 'google.com':
              return (
                <Button
                  type="button"
                  key={loginMethod}
                  onClick={() => handleSignInWithExistingAccount(googleSignIn)}
                >
                  Google
                </Button>
              );
            case 'facebook.com':
              return (
                <Button
                  type="button"
                  key={loginMethod}
                  onClick={() =>
                    handleSignInWithExistingAccount(facebookSignIn)
                  }
                >
                  Facebook
                </Button>
              );
            case 'twitter.com':
              return (
                <Button
                  type="button"
                  key={loginMethod}
                  onClick={() => handleSignInWithExistingAccount(twitterSignIn)}
                >
                  Twitter
                </Button>
              );

            default:
              break;
          }
          return (
            <Button
              key={loginMethod}
              onClick={() => {
                router.replace({ href: '/sign-in', query: { email } });
              }}
            >
              {t('Email and Password')}
            </Button>
          );
        })}
      </div>
    </div>
  );
}
