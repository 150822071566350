import { AssetDoc } from '@livekatsomo/models';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { useTranslation } from 'next-i18next';
import { ReactNode, forwardRef } from 'react';

/**
 * Props for the AssetSelect component
 */
export type AssetSelectProps = {
  /** Array of AssetDoc objects to be displayed in the dropdown */
  assets: AssetDoc[];
  /** Label to be displayed above the dropdown */
  label?: ReactNode;
  /** Error message to be displayed if there is an error with the dropdown */
  errorMesssage?: string;
  /** Function to be called when the selected value in the dropdown changes */
  onChange: (value: AssetDoc | []) => void;
} & Pick<StandardTextFieldProps, 'variant' | 'required'> &
  Omit<
    AutocompleteProps<AssetDoc, false, false, false>,
    'options' | 'renderInput' | 'onChange'
  >;

/**
 * A component that renders an Autocomplete input field for selecting assets.
 *
 * @returns A ReactNode representing the AssetSelect component.
 */
export const AssetSelect = forwardRef(function AssetSelect(
  {
    assets,
    label,
    value,
    variant,
    onChange,
    required,
    errorMesssage,
    ...props
  }: AssetSelectProps,
  ref,
) {
  const { t } = useTranslation();
  return (
    <Autocomplete
      ref={ref}
      value={value ? value : null}
      options={assets || []}
      autoHighlight
      freeSolo={false}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option?.filename || ''
      }
      onChange={(event, newValue) => {
        onChange(newValue || []);
      }}
      renderOption={(props, option) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { key, ...rest } = props as any;
        if (!option) return null;
        return (
          <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            key={option.id}
            {...rest}
          >
            <img
              loading="lazy"
              width="20"
              src={option.downloadUrls?.jpeg200x200 || option.originalUrl}
              alt=""
            />
            {option.filename}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || t('Choose a asset')}
          variant={variant}
          required={required}
          helperText={errorMesssage}
          error={!!errorMesssage}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      {...props}
    />
  );
});
