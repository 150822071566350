import Switch from '@mui/material/Switch';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { ReactNode } from 'react';

/**
 * Props for the SwitchField component.
 */
export type SwitchFieldProps<FormValues extends FieldValues> = {
  /**
   * The name of the field in the form.
   */
  name: Path<FormValues>;
  /**
   * The label to display for the field.
   */
  label: ReactNode;
  /**
   * The `react-hook-form` control object.
   */
  control?: Control<FormValues>;
  /**
   * Whether the field should take up the full width of its container.
   */
  fullWidth?: boolean;
} & Omit<FormControlLabelProps, 'control'>;

/**
 * A switch component that can be used with react-hook-form.
 * @typeParam FormValues The type of form values.
 * @returns  The SwitchField component.
 */
export function SwitchField<FormValues extends FieldValues>({
  label,
  name,
  control,
  fullWidth,
  ...props
}: SwitchFieldProps<FormValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          sx={{ width: fullWidth ? '100%' : 'auto' }}
          control={
            <Switch checked={Boolean(field.value)} onChange={field.onChange} />
          }
          label={label}
          {...props}
        />
      )}
    />
  );
}

export default SwitchField;
