import {
  DateTimePicker,
  DateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { differenceInMilliseconds, addMilliseconds } from 'date-fns';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

/**
 * Props for DeltaDateTimePickerField component.
 * @typeParam TFormValues The type of form values.
 */
export interface DeltaDateTimePickerFieldProps<TFormValues extends FieldValues>
  extends Omit<DateTimePickerProps<Date>, 'onChange' | 'value'> {
  /**
   * The name of the field in the form.
   */
  name: Path<TFormValues>;
  /**
   * The reference time to calculate the difference from.
   */
  referenceTime: Date;
  /**
   * Whether to allow only future dates.
   */
  futureOnly?: boolean;
  /**
   * The react-hook-form control object.
   */
  control?: Control<TFormValues>;
}

/**
 * A date time picker field that calculates the difference between the selected date and a reference time.
 * @typeParam TFormValues - The form values.
 * @param props - The component props.
 * @param props.name - The name of the field.
 * @param props.label - The label of the field.
 * @param props.futureOnly - Whether to allow only future dates.
 * @param props.referenceTime - The reference time to calculate the difference from.
 * @param props.control - The react-hook-form control object.
 * @returns  - The date time picker field component.
 */
export function DeltaDateTimePickerField<
  TFormValues extends FieldValues = FieldValues,
>({
  name,
  label,
  futureOnly,
  referenceTime,
  control,
  ...props
}: DeltaDateTimePickerFieldProps<TFormValues>) {
  const {
    field: { onBlur, onChange, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleChange = (newValue: Date | null) => {
    onChange(
      newValue && typeof newValue === 'object'
        ? differenceInMilliseconds(newValue, referenceTime)
        : '',
    );
    onBlur();
  };

  const dateValue = value !== '' ? addMilliseconds(referenceTime, value) : null;

  return (
    <DateTimePicker
      data-chromatic="ignore"
      views={['year', 'month', 'day', 'hours', 'minutes']}
      minDateTime={futureOnly ? referenceTime : undefined}
      value={dateValue}
      label={label}
      onChange={handleChange}
      {...props}
      slotProps={{
        textField: {
          ...props?.slotProps?.textField,
          ref: ref,
          error: !!error,
          helperText: error?.message,
          onBlur: onBlur,
        },
        field: {
          ...props?.slotProps?.field,
          'data-chromatic': 'ignore',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      }}
    />
  );
}

export default DeltaDateTimePickerField;
