import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * Props for the MultipleSelectField component.
 */
export interface MultipleSelectFieldProps<
  Option extends string | { id: string },
  TFormValues extends FieldValues = FieldValues,
> extends FormControlProps {
  /**
   * The control object from react-hook-form.
   */
  control?: Control<TFormValues>;
  /** The options to display in the select field. */
  options: readonly Option[];
  /** The name of the field in the form. */
  name: Path<TFormValues>;
  /** The label to display for the select field. */
  label: string;
  /** A function to transform the option into a string to display in the select field. */
  getOptionLabel: (item: NoInfer<Option>) => string;
  /**
   * A function to get the value for an option (eg. the id of the option in `{id: 123, ...some othe fields}`).
   */
  getOptionValue?: Option extends string
    ? never
    : (option: NoInfer<Option>) => string;
}

/**
 * A custom React Hook Form component for rendering a multiple select field.
 *
 * @typeParam Option - The type of the options in the select field.
 * @typeParam TFormValues - The type of the form values.
 *
 * @param props - The props for the component.
 * @param props.options - The options to display in the select field.
 * @param props.name - The name of the field.
 * @param props.label - The label for the field.
 * @param props.optionViewer - A function that returns the React node to display for each option.
 *
 * @returns  - The JSX element for the multiple select field.
 */
export function MultipleSelectField<
  Option extends string | { id: string } = string,
  TFormValues extends FieldValues = FieldValues,
>({
  options,
  name,
  label,
  getOptionLabel,
  getOptionValue,
  ...props
}: MultipleSelectFieldProps<Option, TFormValues>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
  });

  console.log('field', field);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    const selectedOptions = options.filter((option) =>
      typeof option === 'string'
        ? value.includes(option)
        : value.includes(option.id),
    );
    field.onChange(
      selectedOptions.map((option) =>
        typeof option === 'string'
          ? option
          : getOptionValue?.(option) ?? option,
      ),
    );
  };

  return (
    <FormControl fullWidth error={Boolean(error)} {...props}>
      <InputLabel id={`multiple-${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`multiple-${name}-label`}
        id="multiple-name"
        multiple
        value={[
          ...field.value.map((v: Option) => (typeof v === 'string' ? v : v.id)),
        ]}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(value) => {
          return options
            .filter((option) =>
              value.includes(typeof option === 'string' ? option : option.id),
            )
            .map((option) =>
              typeof option !== 'string' && getOptionLabel
                ? getOptionLabel(option)
                : (option as never),
            )
            .join(', ');
        }}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            key={typeof option === 'string' ? option : option.id}
            value={typeof option === 'string' ? option : option.id}
          >
            {typeof option !== 'string' && getOptionLabel
              ? getOptionLabel(option)
              : (option as never)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}
