import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'next-i18next';

/**
 * CancleAndReset component props
 */
export interface CancleAndResetProps {
  /**
   * Flag indicating whether the form is currently submitting
   */
  isSubmitting: boolean;
  /**
   * Flag indicating whether the form has been modified
   */
  isDirty: boolean;
  /**
   * Function to be called when the component is closed
   */
  onClose?: () => void;
}

/**
 * A component that displays a cancel and reset button for a form
 * @param {CancleAndResetProps} props - The component props
 * @returns The rendered component
 */
export function CancleAndReset({
  isSubmitting,
  isDirty,
  onClose,
}: CancleAndResetProps) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
      }}
    >
      {onClose ? (
        <Button onClick={onClose} type="button">
          {t('Cancel')}
        </Button>
      ) : null}

      <Button type="reset" disabled={isSubmitting || !isDirty}>
        {t('Reset')}
      </Button>
    </Box>
  );
}
