import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

/**
 * Props for the TimePickerField component.
 * @typeParam TFormValues The type of form values object that contains this field.
 */
export interface TimePickerFieldProps<
  TFormValues extends FieldValues = FieldValues,
> extends Omit<TimePickerProps<Date>, 'onChange' | 'defaultValue'> {
  name: Path<TFormValues>;
  control?: Control<TFormValues>;
}

/**
 * A form field component that renders a time picker input using react-hook-form.
 * @typeParam TFormValues The type of form values object that contains this field's value.
 * @param props The props for the TimePickerField component.
 * @returns  The JSX element for the TimePickerField component.
 */
export function TimePickerField<TFormValues extends FieldValues = FieldValues>({
  name,
  control,
  ...props
}: TimePickerFieldProps<TFormValues>) {
  const { field } = useController({ name, control });

  return (
    <div data-chromatic="ignore">
      <TimePicker {...field} {...props} />
    </div>
  );
}

export default TimePickerField;
