import Upload from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDropzone } from 'react-dropzone';
import { ReactNode } from 'react';
import { FileUploadProgess } from '@livekatsomo/types';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { UploadButtonProps, UploadButton } from './UploadButton';

/**
 * Props for the UploadButtonField component.
 * @typeParam TFormValues The type of form values.
 */
export interface UploadButtonFieldProps<
  TFormValues extends FieldValues = FieldValues,
> extends Pick<UploadButtonProps, 'variant'> {
  name: Path<TFormValues>;
  control?: Control<TFormValues>;
  label?: ReactNode;
  uploadProgress: FileUploadProgess;
  required?: boolean;
}

/**
 * A component that renders an image upload button with drag and drop functionality.
 * @param name - The name of the field in the form.
 * @param control - The control object provided by the `useForm` hook from `react-hook-form`.
 * @param label - The label to display above the button.
 * @param uploadProgress - An object containing the upload progress for each file.
 * @param variant - The variant of the button.
 * @param required - Whether the field is required or not.
 * @returns A React component that renders an image upload button with drag and drop functionality.
 */
export function UploadButtonField<
  TFormValues extends FieldValues = FieldValues,
>({
  name,
  control,
  label,
  uploadProgress,
  variant,
  required,
}: UploadButtonFieldProps<TFormValues>) {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController<TFormValues>({
    name,
    control,
  });
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      multiple: false,
      noClick: true,
      onDrop: (acceptedFiles) => {
        field.onChange(acceptedFiles);
        field.onBlur();
      },
    });

  const progress =
    Array.isArray(field.value) && field.value.length && uploadProgress
      ? uploadProgress[field.value[0]?.name]?.progress
      : 0;

  return (
    <Box {...getRootProps()} sx={{ position: 'relative' }}>
      <UploadButton
        {...field}
        label={label}
        uploadProgress={progress}
        variant={variant}
        required={required}
      >
        <input {...getInputProps()} />
      </UploadButton>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          textAlign: 'center',
          display: isDragActive ? 'flex' : 'none',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: isDragReject
            ? (theme) => alpha(theme.palette.error.main, 0.8)
            : (theme) => alpha(theme.palette.grey[900], 0.8),
          color: 'common.white',
        }}
      >
        <Typography color="grey.100" variant="h4">
          {isDragReject ? t('Filetype not accepted') : t('Drop here')}
        </Typography>
        <Upload fontSize="large" />
      </Box>
      {error ? <Typography color="error">{error.message}</Typography> : null}
    </Box>
  );
}

export default UploadButtonField;
