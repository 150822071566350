import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup, { FormGroupProps } from '@mui/material/FormGroup';
import React from 'react';
import { useController } from 'react-hook-form';

/**
 * Props for the CheckboxGroup component.
 */
export interface CheckboxGroupProps<Model> extends FormGroupProps {
  /**
   * The name of the field in the form.
   */
  name: string;
  /**
   * The list of options to display as checkboxes.
   */
  options: Model[];
  /**
   * A function that returns the unique ID for an option.
   */
  optionId: (option: Model) => string;
  /**
   * A function that returns the value for an option.
   */
  optionValue: (option: Model) => unknown;
  /**
   * A function that returns the label for an option.
   */
  optionLabel: (option: Model) => string;
}

/**
 * A component that displays a group of checkboxes.
 */
export const CheckboxGroup = <Model,>({
  name,
  options,
  children,
  optionId,
  optionValue,
  optionLabel,
  ...props
}: CheckboxGroupProps<Model>) => {
  const { field } = useController({ name });

  /**
   * Handles the onChange event for a checkbox.
   * @param event The onChange event.
   * @param option The option that was changed.
   */
  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: Model,
  ) => {
    const checked = event.target.checked;
    if (checked) {
      field.onChange([...(field.value || []), optionValue(option)]);
    } else {
      field.onChange(
        field.value?.filter((item: unknown) => item !== optionValue(option)),
      );
    }
    field.onBlur();
  };

  return (
    <FormGroup {...props}>
      {options.map((option) => (
        <FormControlLabel
          key={optionId(option)}
          control={
            <Checkbox
              checked={field.value?.includes(optionValue(option))}
              onChange={(e) => handleOnChange(e, option)}
              name={optionLabel(option)}
            />
          }
          label={optionLabel(option)}
        />
      ))}
    </FormGroup>
  );
};

export default CheckboxGroup;
