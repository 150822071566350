import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import debouncePromise from 'awesome-debounce-promise';
import { useEffect, useMemo } from 'react';
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';
import slugify from 'slugify';

/**
 * Props for the SlugField component.
 */
export interface SlugFieldProps<TFormValues extends FieldValues = FieldValues> {
  /**
   * The name of the field in the form.
   */
  name: Path<TFormValues>;
  /**
   * The source of the field in the form.
   */
  source: Path<TFormValues>;
  /**
   * Whether to automatically update the slug when the source value changes.
   */
  autoupdate?: boolean;
  /**
   * A validation function that returns a promise resolving to an error message if the value is invalid, or undefined if the value is valid.
   * @param value The value to validate.
   */
  validate: (value: string) => Promise<string | undefined>;
}

/**
 * A custom text field component that generates a slug based on a source field.
 * @typeParam TFormValues The type of form values.
 * @returns  The SlugField component.
 */
export function SlugField<TFormValues extends FieldValues = FieldValues>({
  name,
  source,
  validate,
  autoupdate = true,
  ...props
}: TextFieldProps & SlugFieldProps<TFormValues>) {
  const handleValidate = useMemo(
    () =>
      debouncePromise(async (value) => {
        const validation = await validate(value);
        return validation;
      }, 1000),
    [validate],
  );

  const { watch, setError, clearErrors } = useFormContext();

  const {
    field,
    fieldState: { isTouched, error },
  } = useController({
    name,
  });

  useEffect(() => {
    /**
     * Subscribes to changes in the form field and updates the slug field if necessary.
     * Also handles validation errors and sets appropriate error messages.
     *
     * @param value - The current form values.
     * @param changedField - The name of the field that was changed.
     */
    const subscription = watch(async (value, { name: changedField, type }) => {
      if (changedField === name) {
        // Check if there is current validation error for the field.
        console.log(error);
        console.log(type);

        const validationError = await handleValidate(value[name]);
        if (validationError) {
          setError(
            name,
            { message: validationError, type: 'validate' },
            { shouldFocus: true },
          );
        } else {
          clearErrors(name);
        }
      }
      // If the source field was changed, slug had not been changed manually and autoupdate is enabled,
      // update the slug field.
      if (!isTouched && changedField === source && autoupdate) {
        field.onChange(slugify(value[source], { lower: true, strict: true }));
      }
    });
    return () => subscription.unsubscribe();
  }, [
    watch,
    setError,
    clearErrors,
    handleValidate,
    name,
    isTouched,
    source,
    autoupdate,
    field,
    error,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(slugify(event.target.value, { lower: true, strict: true }));
  };

  return (
    <MuiTextField
      {...field}
      {...props}
      error={Boolean(error)}
      helperText={error ? error.message : ''}
      onChange={handleChange}
    />
  );
}

export default SlugField;
