import { KatsomoError, KatsomoErrorCodes } from '@livekatsomo/custom-errors';
import {
  ConfirmPasswordReset,
  SignInWithEmailAndPassword,
  VerifyPasswordResetCode,
} from '@livekatsomo/types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import { PasswordResetForm } from '../PasswordResetForm/PasswordResetForm';

/**
 * Props for the ResetPassword component.
 */
export interface ResetPasswordProps {
  oobCode: string;
  continueUrl: string;
  lang: string;
  verifyPasswordResetCode: VerifyPasswordResetCode;
  confirmPasswordReset: ConfirmPasswordReset;
  signInWithEmailAndPassword: SignInWithEmailAndPassword;
}

/**
 * ResetPassword component for resetting user's password.
 * @param oobCode - The password reset code.
 * @param continueUrl - The URL to redirect the user after successful password reset.
 * @param lang - The language code for localization.
 * @param {VerifyPasswordResetCode} verifyPasswordResetCode - Function to verify the password reset code.
 * @param {ConfirmPasswordReset} confirmPasswordReset - Function to confirm the password reset.
 * @param {SignInWithEmailAndPassword} signInWithEmailAndPassword - Function to sign in with email and password.
 * @returns The ResetPassword component.
 */
export function ResetPassword({
  oobCode,
  continueUrl,
  lang,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
}: ResetPasswordProps) {
  const [codeVerified, setCodeVerified] = useState(false);
  const [email, setEmail] = useState('');
  const [passwordReseted, setPasswordReseted] = useState(false);
  // Localize the UI to the selected language as determined by the lang
  // parameter.
  const { t } = useTranslation();

  // Verify the password reset code is valid.
  useEffect(() => {
    verifyPasswordResetCode(oobCode)
      .then((email) => {
        setCodeVerified(true);
        setEmail(email);
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        throw new KatsomoError(
          'Invalid or expired action code.',
          KatsomoErrorCodes.INVALID_OR_EXPIRED_ACTION_CODE_FOR_PASSWORD_RESET,
        );
      });
  }, [confirmPasswordReset, continueUrl, oobCode, verifyPasswordResetCode]);

  const handlePasswordReset = useCallback(
    async (newPassword: string) => {
      await confirmPasswordReset(oobCode, newPassword);
      await signInWithEmailAndPassword(email, newPassword);
      setPasswordReseted(true);
    },
    [confirmPasswordReset, email, oobCode, signInWithEmailAndPassword],
  );

  if (!codeVerified)
    return (
      <>
        <Typography variant="h5">{t('Verifying action code')}</Typography>
        <CircularProgress />
      </>
    );

  if (!passwordReseted)
    return <PasswordResetForm onResetPassword={handlePasswordReset} />;

  return (
    <>
      <Typography variant="h5">
        {t('Password reseted successfully!')}
      </Typography>
      {continueUrl ? (
        <Typography variant="h6">
          <Link href={continueUrl}>{t('Continue to where you were')}</Link>
        </Typography>
      ) : null}
    </>
  );
}
