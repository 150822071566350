import { useTranslation } from 'next-i18next';

/**
 * A component that displays an error message when the sign in process is canceled by closing the popup window.
 * @returns A JSX element that displays the error message.
 */
export function PopupClosedError() {
  const { t } = useTranslation();
  return <span>{t('Sign In canceled by closing popup window.')}</span>;
}
