import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

/**
 * VerifyEmailExpiredOrInvalidCode component props
 */
export interface VerifyEmailExpiredOrInvalidCodeProps {
  /**
   * Email address to resend verification email
   */
  email?: string | null;
  /**
   * Function to handle resend verification email
   * @param email - Email address to resend verification email
   */
  onResend: (email: string) => void;
}

/**
 * Component for displaying invalid or expired verification code message and option to resend verification email
 * @param onResend - Function to handle resend verification email
 * @param email - Email address to resend verification email
 * @returns JSX.Element
 */
export function VerifyEmailExpiredOrInvalidCode({
  onResend,
  email,
}: VerifyEmailExpiredOrInvalidCodeProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5">{t('Invalid or expired code.')}</Typography>
      {email ? (
        <Button variant="outlined" onClick={() => onResend(email)}>
          {t('Resend verification email')}
        </Button>
      ) : null}
    </>
  );
}
