import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@livekatsomo/web/ui-components/react-hook-form-components';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { ReactNode, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';

/**
 * Password reset form schema.
 */
const formSchema = z
  .object({
    password: z.string().min(8),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    params: {
      i18n: { key: 'Passwords must match' },
    },
    path: ['confirmPassword'],
  });

/**
 * Props for the PasswordResetForm component.
 */
export interface PasswordResetFormProps {
  /**
   * Function to be called when the user submits the new password.
   * @param password - The new password entered by the user.
   */
  onResetPassword: (password: string) => Promise<void>;
}

/**
 * Renders a form for resetting a user's password.
 *
 * @param onResetPassword - A function that is called when the form is submitted with a valid password.
 * @returns A React component that displays a form for resetting a user's password.
 */
export function PasswordResetForm({ onResetPassword }: PasswordResetFormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState<ReactNode>(null);

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const { t } = useTranslation();
  z.setErrorMap(makeZodI18nMap({ t, handlePath: { ns: ['common', 'zod'] } }));

  const methods = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  });

  const {
    formState: { isDirty, isSubmitting, isValid },
    reset,
    handleSubmit,
  } = methods;

  const submit = async ({ password }: typeof initialValues) => {
    try {
      await onResetPassword(password);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  return (
    <Paper
      sx={{
        marginTop: 8,
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)} onReset={() => reset()}>
          <Typography component="h1" variant="h5">
            {t('Add new password')}
          </Typography>
          <TextField
            name="password"
            margin="normal"
            required
            fullWidth
            label={t('Password')}
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
          />
          <TextField
            name="confirmPassword"
            margin="normal"
            required
            fullWidth
            label={t('Confirm Password')}
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
          />
          {ErrorMessage ? (
            <Typography role="alert" color="error">
              {ErrorMessage}
            </Typography>
          ) : null}
          <FormControlLabel
            control={
              <Switch
                value={showPassword}
                onChange={(e, checked) => setShowPassword(checked)}
              />
            }
            label={t('Show password')}
          />
          <Button type="reset" disabled={isSubmitting || !isDirty}>
            {t('Reset')}
          </Button>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting || !isValid || !isDirty}
            loading={isSubmitting}
          >
            {t('Register')}
          </LoadingButton>
        </form>
      </FormProvider>
    </Paper>
  );
}
