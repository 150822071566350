import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

/**
 * Renders a component to display a message and a loading spinner after a user has signed in successfully.
 */
export function SignedInSuccesfully() {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6">
        {t('Signed in successfully. Loading user details.')}
      </Typography>
      <CircularProgress />
    </>
  );
}
