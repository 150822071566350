import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { ChangeEvent } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';

/**
 * Props for the MultipleSelectCheckboxField component.
 * @typeParam TFormValues The type of form values object that the field belongs to.
 * @typeParam TOption The type of the options in the select field.
 */
export type MultipleSelectCheckboxFieldProps<
  TFormValues extends FieldValues,
  TOption extends string | Record<string, unknown> = string,
> = {
  /**
   * The name of the field in the form.
   */
  name: Path<TFormValues>;
  label: string;
  helperText?: string;
  /**
   * The options to display as checkboxes.
   */
  options: TOption[];
  /**
   * A function to get the label for an option.
   */
  getOptionLabel?: TOption extends string ? never : (option: TOption) => string;
  /**
   * A function to get the value for an option.
   */
  getOptionValue?: TOption extends string ? never : (option: TOption) => string;
} & (
  | {
      options: Record<string, unknown>[];
      getOptionLabel: (option: TOption) => string;
      getOptionValue: (option: TOption) => string;
    }
  | {
      options: string[];
    }
);

/**
 * Renders a multiple select checkbox field using react-hook-form.
 * @typeParam TFormValues The type of form values.
 * @typeParam TOption The type of options for the select field.
 * @returns  The JSX element for the component.
 */
export function MultipleSelectCheckboxField<
  TOption extends string | Record<string, unknown> = string,
  TFormValues extends FieldValues = FieldValues,
>({
  name,
  options,
  label,
  helperText,
  required,
  getOptionLabel,
  getOptionValue,
}: CheckboxProps & MultipleSelectCheckboxFieldProps<TFormValues, TOption>) {
  const { field, fieldState } = useController<TFormValues>({
    name,
  });

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      field.onChange([
        ...(field.value || []),
        typeof value !== 'string' && getOptionValue
          ? getOptionValue(value)
          : value,
      ]);
    } else {
      field.onChange(
        field.value?.filter((item: string) =>
          typeof value !== 'string' && getOptionValue
            ? getOptionValue(value) !== item
            : value !== item,
        ),
      );
    }
  };

  return (
    <FormControl>
      <FormLabel required={required}>{label}</FormLabel>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={
              typeof option !== 'string' && getOptionValue
                ? getOptionValue(option)
                : (option as string)
            }
            control={
              <Checkbox
                value={
                  typeof option !== 'string' && getOptionValue
                    ? getOptionValue(option)
                    : option
                }
                checked={field.value?.includes(
                  typeof option !== 'string' && getOptionValue
                    ? getOptionValue(option)
                    : option,
                )}
                onChange={handleCheckboxChange}
              />
            }
            label={
              typeof option !== 'string' && getOptionLabel
                ? getOptionLabel(option)
                : (option as string)
            }
          />
        ))}
      </FormGroup>
      <FormHelperText error={Boolean(fieldState.error)}>
        {fieldState.error?.message || helperText}
      </FormHelperText>
    </FormControl>
  );
}

export default MultipleSelectCheckboxField;
