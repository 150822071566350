import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

/**
 * Props for the TextField component.
 */
export interface TextFieldProps<TFormValues extends FieldValues = FieldValues> {
  /**
   * The name of the field in the form.
   */
  name: Path<TFormValues>;
  /**
   * The control object from react-hook-form.
   */
  control?: Control<TFormValues>;
}

/**
 * A custom TextField component that integrates with react-hook-form.
 */
export function TextField<TFormValues extends FieldValues = FieldValues>({
  name,
  control,
  ...props
}: MuiTextFieldProps & TextFieldProps<TFormValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiTextField
          {...field}
          {...props}
          value={field.value ?? ''}
          error={Boolean(error)}
          helperText={error ? error.message : props.helperText}
        />
      )}
    />
  );
}
