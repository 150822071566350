import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import { FileAvatar } from './FileAvatar';
import IconButton from '@mui/material/IconButton';
import Clear from '@mui/icons-material/Clear';

/**
 * Props for the UploadListItem component.
 */
export interface UploadListItemProps {
  /** The file being uploaded. */
  file: File;
  /** The progress of the upload, as a percentage. */
  progress: number;
  /** An optional error message to display. */
  error?: string;
  /** A function to call when the delete button is clicked. */
  onDelete?: (id: string) => Promise<void>;
  onClearErrored?: (file: File) => void;
}

/**
 * A component to display an item in an upload list.
 */
export function UploadListItem({
  file,
  progress,
  error,
  onDelete,
  onClearErrored,
}: UploadListItemProps) {
  if (!file) return null;
  return (
    <ListItem
      secondaryAction={
        error && onClearErrored ? (
          <IconButton onClick={() => onClearErrored(file)}>
            <Clear />
          </IconButton>
        ) : null
      }
      sx={
        error
          ? {
              backgroundColor: 'error.main',
            }
          : {}
      }
    >
      <ListItemAvatar>
        <FileAvatar file={file} />
      </ListItemAvatar>
      <ListItemText
        sx={{
          '.MuiListItemText-primary': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        primary={file.name}
        secondary={
          error ? (
            error
          ) : (
            <LinearProgress variant="determinate" value={progress} />
          )
        }
      />
    </ListItem>
  );
}

export default UploadListItem;
