import { zodResolver } from '@hookform/resolvers/zod';
import { SigninProcessStatus } from '@livekatsomo/types';
import { Link } from '@livekatsomo/web/ui-components/link';
import { TextField } from '@livekatsomo/web/ui-components/react-hook-form-components';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Key';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import Grid from '@mui/system/Unstable_Grid';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';
import { CancleAndReset } from '../components/CancleAndReset';

/**
 * The schema for the sign-in form, which includes validation rules for the email and password fields.
 */
const formSchema = z.object({
  email: z.string().email().nonempty(),
  password: z.string().nonempty(),
});

/**
 * Props for the SignInForm component.
 */
export interface SignInFormProps {
  email?: string;
  errorMessage?: React.ReactNode;
  processing?: SigninProcessStatus;
  onSignInWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<void>;
  onClose?: () => void;
  onSwitchToRegister?: (email: string) => void;
  onSwitchToForgotPassword?: (email: string) => void;
}

/**
 * A form component for signing in with email and password.
 *
 * @param email The default email value for the form.
 * @param errorMessage The error message to display.
 * @param processing The status of the sign-in process.
 * @param onSignInWithEmailAndPassword The function to call when the form is submitted.
 * @param onClose The function to call when the form is closed.
 * @param onSwitchToRegister The function to call when the user wants to switch to the registration form.
 * @param onSwitchToForgotPassword The function to call when the user wants to switch to the forgot password form.
 */
export function SignInForm({
  email: defaultEmail,
  errorMessage,
  processing,
  onSwitchToRegister,
  onSwitchToForgotPassword,
  onSignInWithEmailAndPassword,
  onClose,
}: SignInFormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();
  const { t } = useTranslation();
  z.setErrorMap(makeZodI18nMap({ t, handlePath: { ns: ['common', 'zod'] } }));

  const initialValues = { email: defaultEmail || '', password: '' };

  const methods = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const {
    formState: { isDirty, isSubmitting, isValid },
    reset,
    handleSubmit,
    watch,
  } = methods;

  const email = watch('email');

  const submit = async ({ email, password }: typeof initialValues) => {
    await onSignInWithEmailAndPassword(email, password);
  };

  const switchToSignInMessage = t("Don't have an account? Sign Up");
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} onReset={() => reset()}>
        <TextField
          name="email"
          margin="normal"
          required
          fullWidth
          data-test="email-field"
          label={t('Email Address')}
          autoComplete="email"
          // autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="password"
          margin="normal"
          required
          fullWidth
          data-test="password-field"
          label={t('Password')}
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PasswordIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={
            <Switch
              value={showPassword}
              onChange={(e, checked) => setShowPassword(checked)}
            />
          }
          label={t('Show password')}
        />
        <Box sx={{ color: (theme) => theme.palette.error.main }}>
          {errorMessage}
        </Box>
        <CancleAndReset
          onClose={onClose}
          isDirty={isDirty}
          isSubmitting={isSubmitting}
        />
        <LoadingButton
          type="submit"
          fullWidth
          data-test="sign-in-button"
          variant="contained"
          loading={isSubmitting}
          sx={{ mb: 2 }}
          disabled={isSubmitting || !isDirty || !isValid || !!processing}
        >
          {t('Sign In')}
        </LoadingButton>
        <Grid container>
          <Grid xs>
            {onSwitchToForgotPassword ? (
              <Button
                variant="text"
                onClick={() => onSwitchToForgotPassword(email)}
              >
                {t('Forgot password?')}
              </Button>
            ) : (
              <Link
                href={{
                  pathname: '/forgot-password',
                  query: { ...router.query, email: email },
                }}
                variant="body2"
              >
                {t('Forgot password?')}
              </Link>
            )}
          </Grid>
          <Grid xs>
            {onSwitchToRegister ? (
              <Button variant="text" onClick={() => onSwitchToRegister(email)}>
                {switchToSignInMessage}
              </Button>
            ) : (
              <Link
                href={{
                  pathname: '/register',
                  query: {
                    ...router.query,
                    ...(router.pathname !== '/sign-in' && {
                      redirectTo: router.asPath,
                    }),
                  },
                }}
                variant="body2"
              >
                {switchToSignInMessage}
              </Link>
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
