import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import FileUploadIcon from '@mui/icons-material/UploadFile';
import PdfFileIcon from '@mui/icons-material/PictureAsPdf';
import VideoFileIcon from '@mui/icons-material/VideoFile';

/**
 * Props for the FileAvatar component.
 */
export interface FileAvatarProps {
  file: File;
}

/**
 * Displays an avatar icon based on the file type.
 * @param {FileAvatarProps} props - The props object containing the file to display.
 * @param props.file - The file to display.
 * @returns  - The avatar icon component.
 */
export function FileAvatar({ file }: FileAvatarProps) {
  if (!file) return null;

  if (file.type.includes('image')) {
    return (
      <Avatar src={URL.createObjectURL(file)}>
        <ImageIcon />
      </Avatar>
    );
  }

  if (file.type.includes('pdf')) {
    return (
      <Avatar>
        <PdfFileIcon />
      </Avatar>
    );
  }

  if (file.type.includes('video')) {
    return (
      <Avatar>
        <VideoFileIcon />
      </Avatar>
    );
  }

  return (
    <Avatar>
      <FileUploadIcon />
    </Avatar>
  );
}
