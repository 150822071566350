import { NextLinkComposed } from '@livekatsomo/web/ui-components/link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

/**
 * Props for the PasswordReseted component.
 */
export interface PasswordResetedProps {
  /**
   * The URL to redirect to after the password has been reset.
   */
  continueUrl?: string;
  /**
   * The email address of the user whose password has been reset.
   */
  email: string;
}

/**
 * Renders a component to display a success message after resetting a password.
 * @param {object} props - The component props.
 * @param props.email - The email address of the user.
 * @param [props.continueUrl] - The URL to redirect the user to after resetting their password.
 * @returns The PasswordReseted component.
 */
export function PasswordReseted({ continueUrl, email }: PasswordResetedProps) {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h4">
        {t('Password reseted successfully!')}
      </Typography>
      {continueUrl ? (
        <Button
          variant="text"
          component={NextLinkComposed}
          to={{
            pathname: '/sign-in',
            query: { redirectTo: continueUrl, email },
          }}
        >
          {t('Continue to sign-in')}
        </Button>
      ) : null}
    </>
  );
}
